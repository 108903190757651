import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'button']
  static values = {
    opened: Boolean
  }

  toggle() {
    this.checkboxTargets.forEach(element => {
      element.checked = !this.openedValue
    });

    this.openedValue = !this.openedValue
    this.buttonTarget.textContent = this.openedValue ? 'Collapse All' : 'Expand All'
  }
}
