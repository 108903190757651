import { Sortable } from '@shopify/draggable';
import { patch } from '@rails/request.js';

// TODO: Stimulus Controller?
document.addEventListener('turbo:load', () => {
  const sortableContainers = document.querySelectorAll('[data-sortable]')

  const sortable = new Sortable(sortableContainers, {
    draggable: '[data-sortable-item]',
    distance: 20
  });

  sortable.on('sortable:sort', (e) => {
    const sourceContainerData = e.dragEvent.sourceContainer.dataset
    const overContainerData = e.dragEvent.overContainer.dataset

    if (sourceContainerData.sortableKind !== overContainerData.sortableKind) {
      e.cancel()
    }
  })

  sortable.on('sortable:stop', async ({dragEvent, newContainer, oldContainer, newIndex, oldIndex}) => {
    const { sortableContainerId: process_group_id } = newContainer.dataset
    const { sortableItemId: id } = dragEvent.source.dataset
    const position = newIndex + 1

    if (newContainer != oldContainer || newIndex != oldIndex) {
      const response = await patch(`/unit_operations/${id}/sort`, {
        contentType: 'application/json',
        body: { position, process_group_id }
      })

      if (!response.ok) {
        console.error('Failed to update position')
      } else {
        console.log('Successfully updated position') // TODO: remove
      }
    }
  })
})
