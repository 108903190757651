import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dropdown', 'name']

  updateName(event) {
    event.preventDefault()

    const { options, selectedIndex } = this.dropdownTarget
    this.nameTarget.value = options[selectedIndex].text
  }
}
