import { Controller } from '@hotwired/stimulus'
import Jets from 'jets'

export default class extends Controller {
  static values = {
    nonce: String,
    searchSelector: {
      type: String,
      default: '[data-search-target="input"]'
    },
    contentSelector: {
      type: String,
      default: '[data-search-target="content"]'
    },
    columns: {
      type: Array,
      default: [0, 1, 2] // search only the first 3 columns
    }
  }

  connect() {
    this.jets = new Jets({
      searchTag: this.searchSelectorValue,
      contentTag: this.contentSelectorValue,
      nonceId: this.nonceValue,
      columns: this.columnsValue,
    })
  }

  disconnect() {
    this.jets.destroy()
    this.#clearStyles()
  }

  // programmatic search
  perform({params: { query }}) {
    this.jets.search(query)
  }

  #clearStyles() {
    const styleTags = Array.from(document.querySelectorAll('style'))
    const cachedFilter = styleTags.find(element => element.textContent.includes(this.contentSelectorValue))
    if (cachedFilter) { cachedFilter.remove() }
  }
}
