import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['input']
  static values = {
    url: String,
    count: Number,
  }

  async perform({currentTarget: {name, value, tomselect}, params: {url: urlParam, value: valueParam}}) {
    if (tomselect) value = tomselect.items

    const fetchURL = new URL(urlParam || this.urlValue)
    const query = new URLSearchParams(fetchURL.search)

    if (this.hasInputTarget) {
      this.inputTargets.forEach(input => query.set(input.name, this.#valueFrom(input)))
    } else {
      query.set(name, valueParam ?? value)
    }

    const response = await get(fetchURL, {query, responseKind: 'turbo-stream'})
    if (response.ok) this.countValue += 1
  }

  #valueFrom(input) {
    if (input.type === 'checkbox') {
      return input.checked
    } else if (input.tomselect) {
      return input.tomselect.items
    } else {
      return input.value
    }
  }
}
